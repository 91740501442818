<template>
	<div class="publicTop">
		<div>
			<img src="../../assets/img/home/logo2.png" alt="">
		</div>
		<div class="public">
			<row :gutter="24">
				<Col span="4">
					<router-link :to="{name:'Home'}">
						<p class="titleFont" @mouseover="init(1)" @mouseout="out" @click="changePage(1)">首页</p>
					</router-link>
					<div class="switchBar"></div>
				</Col>
				<Col span="5">
					<router-link :to="{name:'products'}">
						<p class="titleFont" @mouseover="init(2)" @mouseout="out" @click="changePage(2)">产品与服务</p>
					</router-link>
					<div class="switchBar"></div>
				</Col>
				<Col span="4">
					<router-link :to="{name:'news'}">
						<p class="titleFont" @mouseover="init(3)" @mouseout="out" @click="changePage(3)">新闻资讯</p>
					</router-link>
					<div class="switchBar"></div>
				</Col>
				<Col span="4">
					<router-link :to="{name:'About'}">
						<p class="titleFont" @mouseover="init(4)" @mouseout="out" @click="changePage(4)">关于旭普</p>
					</router-link>
					<div class="switchBar"></div>
				</Col>
				<Col span="4">
					<p class="titleFont" @mouseover="init(5)" @mouseout="out" @click="changePage(5)">招聘信息</p>
					<div class="switchBar"></div>
				</Col>
			</row>
		</div>
		<!-- <div class="switchBox">
			
			<div class="switchBar"></div>
			<div class="switchBar"></div>
			<div class="switchBar"></div>
			<div class="switchBar"></div>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: 'top',
		data() {
			return {
				pageid: 0
			}
		},
		methods: {
			init(id) {
				var titleFont = document.querySelectorAll(".titleFont")
				titleFont.forEach((item,index)=>{
					item.style.cssText = "color: #101212;"
				})
				if (id == 1) {
					titleFont[0].style.cssText = "color: #0079D3;"
				} else if (id == 2) {
					titleFont[1].style.cssText = "color: #0079D3;"
				} else if (id == 3) {
					titleFont[2].style.cssText = "color: #0079D3;"
				} else if (id == 4) {
					titleFont[3].style.cssText = "color: #0079D3;"
				} else if (id == 5) {
					titleFont[4].style.cssText = "color: #0079D3;"
				}
			},
			out() {
				var titleFont = document.querySelectorAll(".titleFont")
				titleFont.forEach((item,index)=>{
					item.style.cssText = "color: #101212;"
				})
				if(this.pageid == 2){
					titleFont[1].style.cssText = "color: #0079D3;"
				}else if(this.pageid == 3){
					titleFont[2].style.cssText = "color: #0079D3;"
				}else if(this.pageid == 4){
					titleFont[3].style.cssText = "color: #0079D3;"
				}else if(this.pageid == 5){
					titleFont[4].style.cssText = "color: #0079D3;"
				}
			},
			changePage(id){
				this.pageid = id
				this.out()
				if(id != 5){
					this.$emit('pageid', id)
				}
				if(id == 5){
					this.$emit('openWindow', this.showRecruit = true)
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.publicTop {
		display: flex;
		height: 83px;
		padding: 17px 0 17px 371px;
		align-items: center;
		>div:first-of-type{
			img{
				width: 100px;
				height: 44px;
			}
		}
		.public {
			width: 740px;
			margin-left: 220px;
			// display: flex;
			// align-items: center;
			// justify-content: space-around;

			p {
				// width: 95px;
				color: black;
				font-size: 16px;
				color: #101212;
				font-weight: 600;
				cursor: pointer;
				text-align: center;
			}
			p:active{
				color: #00396F !important;
			}
		}
		.switchBox{
			position: absolute;
			top: 79px;
			left:693px;
			width: 740px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}
		.switchBar {
			width: 80px;
			height: 4px;
			margin: 0 auto;
			position: relative;
			top: 28px;
			visibility: hidden;
			background: linear-gradient(90deg, #0067C7, #00C0FF);
			transition: left 0.5s;
			-moz-transition: left 0.5s; /* Firefox 4 */
			-webkit-transition: left 0.5s; /* Safari 和 Chrome */
			-o-transition: left 0.5s; /* Opera */
		}
	}
</style>