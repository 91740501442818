<template>
	<div class="about">
		<top @openWindow="openWindow"></top>
		<div class="topPic">
			<img src="../../assets/img/about/旭日东升-朝阳普照.png" alt="">
		</div>
		<div class="introduce">
			<div class="introduceText">
				<h4>企业简介</h4>
				<h3>introduce</h3>
				<p>旭普集团（以下简称“集团”）是依托武汉大学空间信息技术与数字工程中心，致力于从事3S（GIS、RS、GNSS）信息技术创新，提供地理空间信息云计算、云存储、云服务卓越产品及其应用的解决方案集成商。</p>
				<p>旭日东升、朝阳普照。2002年，集团发端于羊城广州，现已形成以广东旭普空间信息技术产业发展有限公司、北斗旭普空间信息产业（武汉）有限公司为核心的集团发展布局。</p>
				<p>科技以人为本，集团聚集了一大批有志于3S技术创新和工程应用、云计算研究及致力于推动我国空间信息产业发展的优秀人才，行业专家、博士、硕士以及高级工程技术与管理人员的比例超过了30%。</p>
				<h4>旭普精神</h4>
				<p>不断提高要求，延迟满足感在更大范围里找最优解，不放过问题，思考本质持续学习和成长</p>
				<div class="textPic">
					<div>
						<img src="../../assets/img/about/诚信.png" alt="">
					</div>
					<div>
						<img src="../../assets/img/about/合作.png" alt="">
					</div>
					<div>
						<img src="../../assets/img/about/专业.png" alt="">
					</div>
					<div>
						<img src="../../assets/img/about/组 366.png" alt="">
					</div>
					<div>
						<img src="../../assets/img/about/责任.png" alt="">
					</div>
				</div>
			</div>
			<div class="introducePic">
				<img src="../../assets/img/about/组 365.png" alt="">
			</div>
		</div>
		<div class="contact">
			<div class="contactPic">
				<img src="../../assets/img/about/501004811.png" alt="">
			</div>
			<div class="contactText">
				<div>
					<div></div>
					<h4>联系我们</h4>
					<p>CONTACT US</p>
				</div>
				<div>
					<p>电话:(+8620)8722 0925 (+8620)8722 0965</p>
					<p>传真:(+8620)8722 0915</p>
					<p>网站: http://www.supersit.com</p>
					<p>邮箱:supersit@supersit.com</p>
				</div>
			</div>
		</div>
		<div class="aboutPic">
			<div class="rightAbout">
				<p>旭普成立已有</p>
				<p>19 <span>年</span></p>
			</div>
			<div class="leftAbout">
				<div class="topAbout">
					<div class="firstTopAbout" style="padding-right: 10px;">
						<p>广东旭普空间信息技术</p>
						<p>产业发展有限公司成立</p>
					</div>
					<div class="firstTopAbout bigFont" style="padding-right: 10px;">
						<p>2012</p>
					</div>
					<div class="firstTopAbout">
						<p>广州凡达生态</p>
						<p>环保院成立</p>
					</div>
					<div class="firstTopAbout bigFont">
						<p>至今/未来</p>
					</div>
				</div>
				<div class="topAbout bottomAbout">
					<div class="firstTopAbout bigFont" style="padding-right: 10px;">
						<p>2002</p>
					</div>
					<div class="firstTopAbout" style="padding-right: 10px;">
						<p>北斗旭普空间信息技术</p>
						<p>产业发展有限公司成立</p>
					</div>
					<div class="firstTopAbout bigFont">
						<p>2014</p>
					</div>
					<div class="firstTopAbout">
						<p>集团化格局</p>
						<p>蓄力前行</p>
					</div>
				</div>
			</div>
		</div>
		<div class="voucher">
			<h3>SUPERSIT旭普资质</h3>
			<p>Company news center</p>
			<div class="picBox">
				<div class="voucherPic" v-for="(item,index) in voucherList" :id="index">
					<div>
						<img :src="item.pic" alt="" :class="item.classStyle" @click="openPicture(item.pic,item.name,item.classStyle)">
					</div>
					<div>
						<p>
							<Tooltip :content="item.name">
						       {{item.name}}
						    </Tooltip>
						</p>
						<p>{{item.time}}</p>
					</div>
				</div>
			</div>
			<Page :total="picList.length" :page-size="12" style="margin-bottom: 0.20833333rem;" @on-change="nextPage"/>
		</div>
		<div v-show="dragShow" class="dragBackGround">
			<drag :style="style1">
				<div class="dragWindow">
					<div>
						<!-- <p>{{dragName}}</p> -->
						<Icon type="md-close" @click="closeDrag" style="cursor: pointer;"/>
					</div>
					<div>
						<img :src="dragPic" alt="" class="dragPic">
					</div>
				</div>
			</drag>
		</div>
		<recruit v-show="showRecruit" @close="closeWindow"></recruit>
		<bottom @open="openWindow"></bottom>
	</div>
</template>

<script>
	import top from "../home/top.vue"
	import bottom from "../home/bottom.vue"
	import recruit from "../recruitmentInformation/recruit.vue"
	import drag from '../home/draggableBox.vue'
	export default {
		name: 'about',
		data() {
			return {
				dragPic: "",
				dragName: "",
				dragShow: false,
				showRecruit: false,
				style1: {
					top: '83px',
					left: '33%',
				},
				voucherList:[],
				picList: [{
						pic: require("../../assets/img/about/图片14.jpg"),
						name: "系统集成及服务资质证书",
						time: "2017-09-19",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片15.jpg"),
						name: "测绘资质丙级",
						time: "2014-11-27",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片16.jpg"),
						name: "CMMI3（正本）",
						time: "2019-12-12",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片17.jpg"),
						name: "设计、施工、维修资格证（副本）",
						time: "2019-12-16",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片18.jpg"),
						name: "设计、施工、维修资格证（正本）",
						time: "2019-12-16",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片19.jpg"),
						name: "软件企业证书",
						time: "2017-11-27",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片20.jpg"),
						name: "质量管理体系认证证书（英文）",
						time: "2018-07-21",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片21.jpg"),
						name: "环境管理体系认证证书(英文版）",
						time: "2019-06-14",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片22.jpg"),
						name: "职业健康安全管理体系认证证书(英文版）",
						time: "2019-06-14",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片23.jpg"),
						name: "信息安全管理体系认证证书(英文版）",
						time: "2020-07-07",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片24.jpg"),
						name: "信息技术服务管理体系认证证书(英文版）",
						time: "2019-09-03",
						classStyle: "heightStyle"
					},{
						pic: require("../../assets/img/about/图片1.png"),
						name: "高新技术企业证书",
						time: "2017-12-11",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片2.png"),
						name: "广东省守合同重信用企业（2006-2019连续十四年）",
						time: "2020-06-01",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片3.png"),
						name: "广东省诚信示范企业证书（2009-2019连续十一年）",
						time: "2020-06-01",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片4.png"),
						name: "优秀信用企业（2020年）",
						time: "2020-04-11",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片5.png"),
						name: "CMMI3（副本）",
						time: "2019-12-12",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片6.png"),
						name: "质量管理体系认证证书（中文）",
						time: "2018-07-27",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片7.png"),
						name: "环境管理体系认证证书(中文版）",
						time: "2019-06-14",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片8.png"),
						name: "职业健康安全管理体系认证证书(中文版）",
						time: "2019-06-14",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片9.png"),
						name: "信息安全管理体系认证证书(中文版）",
						time: "2020-01-07",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片10.png"),
						name: "信息技术服务管理体系认证证书(中文版）",
						time: "2019-07-03",
						classStyle: "heightStyle"
					},
					{
						pic: require("../../assets/img/about/图片11.png"),
						name: "企业信用等级证书AAA（中国企业评价协会颁发）",
						time: "2019-04-18",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片12.png"),
						name: "企业信用等级（中国软件行业协会颁发）",
						time: "2018-06-15",
						classStyle: "widthStyle"
					},
					{
						pic: require("../../assets/img/about/图片13.png"),
						name: "2016年度广州市企业研究开发机构证书",
						time: "2016-12-11",
						classStyle: "widthStyle"
					}
				]
			}
		},
		components: {
			top,
			bottom,
			recruit,
			drag
		},
		mounted() {
			this.switchs()
			this.firstPage()
		},
		methods: {
			firstPage(){
				if(this.picList.length <= 12){
					this.voucherList = this.picList
				}else{
					this.voucherList = this.picList.slice(0,12)
				}
			},
			nextPage(page){
				if(page == 1){
					this.voucherList = this.picList.slice(0,12)
				}
				if(page == 2){
					this.voucherList = this.picList.slice(12,24)
				}
			},
			switchs(){
				let titleFont = document.querySelectorAll(".titleFont")
				let switchBar = document.querySelectorAll(".switchBar")
				titleFont.forEach((item, index) => {
					item.style.cssText = "color: #101212;"
				})
				switchBar.forEach((item,index)=>{
					item.style.cssText = " visibility: hidden;"
				})
				switchBar[3].style.cssText = " visibility: visible;"
				titleFont[3].style.cssText = "color: #0079D3;"
			},
			openPicture(pic, name, style) {
				this.dragPic = ""
				this.dragName = ""
				this.dragPic = pic
				this.dragName = name
				this.dragShow = true
				let dragPic = document.querySelector(".dragPic")
				let dragWindow = document.querySelector(".dragWindow")
				if (style == "widthStyle") {
					dragPic.classList = "dragPic veryWidth"
					dragWindow.classList = "dragWindow"
					this.style1.left = "33%"
				}
				if(style == "heightStyle"){
					dragPic.classList = "dragPic veryHeight"
					dragWindow.classList = "dragWindow dragWidth"
					this.style1.left = "40%"
				}
			},
			closeDrag() {
				let dragPic = document.querySelector(".dragPic")
				dragPic.classList = "dragPic"
				this.dragPic = ""
				this.dragName = ""
				this.dragShow = false
			},
			openWindow(){
				this.showRecruit = true
			},
			closeWindow(data){
				this.showRecruit = false
			}
		}
	}
</script>

<style scoped lang="scss">
	.about {
		background-color: #F8F8F8;

		.topPic {
			img {
				width: 1903px;
				height: 380px;
			}
		}

		.introduce {
			display: flex;
			padding: 42px 0 0 388px;

			.introduceText {
				h4 {
					font-size: 18px;
					color: #010101;
					font-weight: 500;
				}

				h4:nth-of-type(2) {
					margin-top: 8px;
					padding-bottom: 12px;
				}

				h3 {
					font-size: 45px;
					font-family: Arial;
					font-weight: bold;
					color: #006FB8;
					line-height: 50px;
					background: linear-gradient(181deg, #0067C7 0%, #00C0FF 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}

				p {
					width: 565px;
					font-size: 16px;
					color: #666666;
					padding-bottom: 10px;
				}

				>p:nth-of-type(3) {
					padding-bottom: 8px;
					border-bottom: 1px solid #E0E0E0;
				}
			}

			.textPic {
				width: 565px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				img {
					width: 66px;
					height: 66px;
				}
			}

			.introducePic {
				padding: 0 0 93px 42px;

				img {
					width: 486px;
					height: 495px;
				}
			}
		}

		.contact {
			padding: 0 0 42px 388px;
			display: flex;

			.contactPic {
				img {
					width: 407px;
					height: 201px;
				}
			}

			.contactText {
				background-color: white;

				>div:first-of-type {
					display: flex;
					align-items: center;
					padding: 25px 0 30px 0;

					>div {
						width: 6px;
						height: 21px;
						background-color: #007BEB;
					}

					>h4 {
						padding: 0 11px 0 23px;
						font-size: 20px;
						color: #2E2E2E;
						font-weight: 800;
					}

					>p {
						font-size: 20px;
						color: #2E2E2E;
					}
				}

				>div:last-of-type {
					width: 630px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin-left: 29px;

					p {
						width: 315px;
						line-height: 34px;
						font-size: 16px;
						color: #666666;
					}
				}
			}
		}

		.aboutPic {
			height: 195px;
			background-image: url(../../assets/img/about/定版风格_03.png);
			background-size: 1903px 195px;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
			color: white;
			
			.leftAbout {
				// padding-left: 320px;
				// padding-top: 80px;
				.topAbout {
					width: 790px;
					display: flex;
					justify-content: space-around;
					align-items: center;
			
					.firstTopAbout {
						width: 185px;
						text-align: center;
						font-size: 14px;
					}
			
					.bigFont {
						font-size: 20px;
					}
				}
			
				.bottomAbout {
					padding-top: 25px;
				}
			}
			
			.rightAbout {
				margin-left: 350px;
				width: 140px;
				text-align: center;
			
				p:first-of-type {
					font-size: 14px;
					padding-bottom: 5px;
				}
			
				p:last-of-type {
					font-size: 44px;
					>span{
						font-size: 14px;
					}
				}
			}
		}

		.voucher {
			width: 1903px;
			padding-top: 48px;
			text-align: center;

			h3 {
				width: 1903px;
				text-align: center;
				font-size: 30px;
				color: #333333;
				font-weight: 600;
			}

			>p {
				width: 1903px;
				text-align: center;
				padding-top: 8px;
				color: #959595;
				font-size: 14px;
				font-weight: 400;
			}

			.picBox {
				width: 1600px;
				padding: 36px 0 0 390px;
				display: flex;
				flex-wrap: wrap;

				.voucherPic {
					width: 264px;
					margin: 0 32px 44px 0;

					>div:first-of-type {
						width: 264px;
						height: 270px;
						background-color: #F6FCFF;
						display: flex;
						align-items: center;
						justify-content: center;

						.widthStyle {
							width: 232.4px;
							height: 157.5px;
							cursor: pointer;
						}

						.heightStyle {
							width: 178px;
							height: 256px;
							cursor: pointer;
						}
					}

					>div:last-of-type {
						width: 264px;
						height: 60px;
						text-align: left;
						padding-left: 13px;
						background-color: white;

						p:first-of-type {
							width: 235px;
							font-size: 16px;
							color: #262626;
							line-height: 18px;
							padding-top: 15px;
							
							::v-deep .ivu-tooltip-rel{
								width: 235px;
								white-space: nowrap;
								overflow: hidden; 
								text-overflow: ellipsis;
							}
							::v-deep .ivu-tooltip-inner{
								max-width: 400px;
								text-align: center;
							}
						}

						p:last-of-type {
							font-size: 14px;
							color: #000000;
							font-weight: 400;
							opacity: 0.5;
							padding-top: 5px;
						}
					}
				}
			}
		}
		.dragBackGround{
			position: fixed;
			top: 0;
			left: 0;
			z-index: 3;
			width: 100vw;
			height: 100vh;
			background-color: rgba($color: #000000, $alpha: 0.5);
			.dragWindow {
				width: 697.2px;
				background-color: white;
				font-size: 20px;
				text-align: right;
				padding-right: 20px;
				.veryWidth {
					width: 697.2px;
					height: 472.5px;
				}
				.veryHeight{
					width: 408px;
					height: 528px;
				}
				i:hover{
					cursor: pointer;
					color: #0067C7;
				}
			}
			.dragWidth{
				width: 408px !important;
			}
		}
	}
</style>
