<template>
	<!-- 拖拽组件（容器） -->
	<div @mousedown="draggable" class="drag-box" ref="dragBox" :style="style1">
		<slot></slot>
	</div>
</template>

<script>
	export default{
		props:{
			style1:{
				type:Object,
				default:()=>{
					top: '83px'
					left: '200px'
				}
			}
		},
		data(){
			return{
				positionX:0,
				positionY:0
			}
		},
		methods:{
			draggable(e){
				let odiv = this.$refs.dragBox; //获取目标元素
				//算出鼠标相对元素的位置
				let disX = e.clientX - odiv.offsetLeft;
				let disY = e.clientY - odiv.offsetTop;
				document.onmousemove = (e)=>{    //鼠标按下并移动的事件
					//用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
					let left = e.clientX - disX;  
					let top = e.clientY - disY;
					 
					//绑定元素位置到positionX和positionY上面
					this.positionX = top;
					this.positionY = left;
					//移动当前元素
					odiv.style.left = left + 'px';
					odiv.style.top = top + 'px';
				};
					document.onmouseup = (e) => {
					document.onmousemove = null;
					document.onmouseup = null;
				};
			}
		}
	}
</script>

<style lang="scss" scoped>
	.drag-box{
		position: fixed;
		z-index: 5;
		right: 300px;
		top: 83px;
	}
</style>
